/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { ShowError } from "../../errors/components/ShowError";
import { RegistrationHeader } from "./RegistrationHeader";
import "./style.css";
import { CSSTransition } from "react-transition-group";

import { Buffer } from "buffer";
import { useSetAuthPin } from "../core/_requests";
import { useNavigate } from "react-router-dom";
import { SeedMnemonicPages } from "./seed-phrase/SeedMnemonicPages";
import { useIntl } from "react-intl";

const initialValuesMask = ["_", "_", "_", "_", "_", "_"];
const regex = /^[0-9]+$/;

window.Buffer = Buffer;

export function RegistrationWithPin() {
  const intl = useIntl();

  const [inputValuePageOne, setInputValuePageOne] = useState("");
  const [inputValuePageTwo, setInputValuePageTwo] = useState("");

  const [inputMaskValues, setInputMaskValues] = useState(initialValuesMask);

  const [indexPass, setIndexPass] = useState<number>(0);
  const [updScore, setUpdScore] = useState<number>(0);
  const [previousValue, setPreviousValue] = useState("");

  const [regPage, setRegPage] = useState<number>(1);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [resetPass, setResetPass] = useState(false);
  const [inputMaskBg, setInputMaskBg] = useState<boolean | null>(null);
  const [error, setError] = useState<any | null>(null);

  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef(null);

  const navigate = useNavigate();

  const {
    mutate: setAuthPin,
    isLoading: isSetAuthPinLoading,
    data: authPinData,
    error: setAuthPinError,
  } = useSetAuthPin();

  useEffect(() => {
    if (authPinData) {
      handleClickNextPage();
    }
  }, [authPinData]);

  useEffect(() => {
    if (setAuthPinError) {
      setError({
        error: intl.formatMessage({ id: "AUTH.REGISTRATION_ERROR" }),
      });
      setInputMaskBg(false);
    }
  }, [setAuthPinError]);

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!regex.test(value) && value !== "") return;

    if (value.length > 6) {
      return;
    } else {
      if (error || inputMaskBg !== null) {
        setError(null);
        setInputMaskBg(null);
      }

      const splitValue: string[] = value.split("");
      const newValues: string[] = [];

      regPage === 1 ? setInputValuePageOne(value) : setInputValuePageTwo(value);

      for (let i = 0; i < 6; i++) {
        if (
          splitValue[i] &&
          i === splitValue.length - 1 &&
          value.length > previousValue.length
        ) {
          newValues.push(splitValue[i]);
        } else if (splitValue[i]) {
          newValues.push("*");
        } else {
          newValues.push("_");
        }
      }

      setInputMaskValues(newValues);

      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(() => {
        if (value.length > previousValue.length) {
          setUpdScore(updScore + 1);
        }
      }, 1000);

      setIndexPass(splitValue.length - 1);
      setPreviousValue(value);
      setTimer(newTimer);
    }
  };

  useEffect(() => {
    if (indexPass > 0) {
      updInputMask();

      if (indexPass === 5) {
        if (regPage === 1) {
          setInputMaskBg(true);

          handleClickNextPage();
        } else if (inputValuePageTwo.length > 0 && regPage === 2) {
          if (inputValuePageTwo === inputValuePageOne) {
            setInputMaskBg(true);

            setAuthPin(inputValuePageTwo);
          } else {
            setError({
              error: intl.formatMessage({ id: "AUTH.INCORRECT_PIN" }),
            });
            handlerInvalidPassword();
          }
        }
      }
    }
  }, [updScore]);

  const updInputMask = () => {
    const newValues = inputMaskValues.map((el, idx) => {
      if (idx === indexPass) {
        return (el = "*");
      }
      return el;
    });

    setInputMaskValues(newValues);
  };

  const nextPage = (page: number) => {
    setRegPage(page);
    setInProp(!inProp);
    setInputMaskValues(initialValuesMask);
    setInputMaskBg(null);
    setIndexPass(0);
    setUpdScore(0);
    setPreviousValue("");
    setError(null);
  };

  const handleClickNextPage = () => {
    setTimeout(() => {
      nextPage(regPage + 1);
    }, 500);
  };

  const handlerInvalidPassword = () => {
    setInputMaskBg(false);
    setInputValuePageOne("");
    setInputValuePageTwo("");
    setInputMaskValues(initialValuesMask);
    setRegPage(1);
  };

  return (
    <div>
      <RegistrationHeader />
      <CSSTransition
        nodeRef={nodeRef}
        in={inProp}
        timeout={400}
        classNames="my-node"
      >
        <div ref={nodeRef}>
          {regPage === 1 ? (
            <>
              <div className="mb-10">
                {/* begin::Title */}
                <h1 className="text-dark fw-semibold mb-3 w-lg-400px display-6 mb-5">
                  {intl.formatMessage({ id: "AUTH.CREATE_CODE_PASSWORD" })}
                </h1>
                {/* end::Title */}
                <p className="text-gray-500 pt-1 fw-semibold fs-6">
                  {intl.formatMessage({
                    id: "AUTH.REQUIRED_FOR_ACCESS_AND_PAYMENTS",
                  })}
                </p>
                {/* end::Title */}
              </div>
              <div
                className={`${
                  inputMaskBg === false ? "shake" : ""
                } d-flex flex-wrap flex-stack justify-content-center algin-items-center mb-13`}
                style={{
                  position: "relative",
                }}
              >
                {inputMaskValues.map((value, index) => (
                  <div
                    key={index}
                    className={`btn display-3 btn-outline btn-outline-${
                      inputMaskBg === null
                        ? null
                        : inputMaskBg
                        ? "success"
                        : "danger"
                    } px-5 pt-4 pb-2 w-45px`}
                    style={{ cursor: "pointer", marginRight: "6px" }}
                  >
                    <span className="">{value}</span>
                  </div>
                ))}

                <input
                  type="number"
                  value={inputValuePageOne}
                  style={{
                    opacity: "0",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                  }}
                  className="form-control form-control-lg form-control-solid"
                  placeholder="XXX-XXX"
                  autoComplete="off"
                  onChange={(e) => {
                    handlePassChange(e);
                  }}
                />
              </div>

              <div className="mx-auto notice d-flex align-items-center bg-light-warning rounded border-warning border border-dashed mb-10 p-3 mw-375px">
                <i className="ki-duotone ki-information fs-2x text-warning me-7">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>

                <h4 className="text-center text-dark fw-semibold mb-0">
                  {intl.formatMessage({ id: "AUTH.DO_NOT_SHARE_PASSWORD" })}
                </h4>
              </div>
            </>
          ) : regPage === 2 ? (
            <>
              <div className="mb-10">
                {/* begin::Title */}
                <h1 className="text-dark fw-semibold mb-3 w-lg-400px display-6 mb-5">
                  {intl.formatMessage({ id: "AUTH.REPEAT_CODE_PASSWORD" })}
                </h1>
                {/* end::Title */}
                <p className="text-gray-500 pt-1 fw-semibold fs-6">
                  {intl.formatMessage({
                    id: "AUTH.REQUIRED_FOR_ACCESS_AND_PAYMENTS",
                  })}
                </p>
                {/* end::Title */}
              </div>
              <div
                className={`${
                  inputMaskBg === false ? "shake" : ""
                } d-flex flex-wrap flex-stack justify-content-center algin-items-center mb-13`}
                style={{
                  position: "relative",
                }}
              >
                {inputMaskValues.map((value, index) => (
                  <div
                    key={index}
                    className={`btn display-3 btn-outline btn-outline-${
                      inputMaskBg === null
                        ? null
                        : inputMaskBg
                        ? "success"
                        : "danger"
                    } px-5 pt-4 pb-2 w-45px`}
                    style={{ cursor: "pointer", marginRight: "6px" }}
                  >
                    <span className="">{value}</span>
                  </div>
                ))}

                <input
                  type="number"
                  value={inputValuePageTwo}
                  style={{
                    opacity: "0",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                  }}
                  className="form-control form-control-lg form-control-solid"
                  placeholder="XXX-XXX"
                  autoComplete="off"
                  onChange={(e) => {
                    handlePassChange(e);
                  }}
                />
              </div>

              <div className="mx-auto notice d-flex align-items-center bg-light-warning rounded border-warning border border-dashed mb-10 p-3 mw-375px">
                <i className="ki-duotone ki-information fs-2x text-warning me-7">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>

                {isSetAuthPinLoading ? (
                  <span
                    className="indicator-progress d-flex flex-center"
                    style={{ display: "block" }}
                  >
                    <span className="spinner-border spinner-border-sm align-middle me-2"></span>
                    <h4 className="text-center text-dark fw-semibold mb-0">
                      {intl.formatMessage({ id: "AUTH.CHECKING_PIN" })}
                    </h4>
                  </span>
                ) : (
                  <h4 className="text-center text-dark fw-semibold mb-0">
                    {intl.formatMessage({ id: "AUTH.DO_NOT_SHARE_PASSWORD" })}
                  </h4>
                )}
              </div>
            </>
          ) : regPage === 3 ? (
            <>
              <div className="mb-20">
                {/* begin::Title */}
                <h1 className="text-dark fw-semibold mb-3 w-lg-400px display-6 mb-5">
                  {intl.formatMessage({
                    id: "AUTH.REGISTRATION_ALMOST_COMPLETE",
                  })}
                </h1>
                {/* end::Title */}
                <p className="text-gray-500 pt-1 fw-semibold fs-6">
                  {intl.formatMessage({ id: "AUTH.WELCOME_TO_SERVICE" })}
                </p>
                {/* end::Title */}
              </div>

              <div className="mb-20 my-sm-20">
                <p className="fs-5 fs-sm-3 fw-semibold text-center">
                  {intl.formatMessage({ id: "AUTH.NEXT_STEP_RECOVERY" })}
                </p>
              </div>
              <div className="d-flex flex-center mb-15 my-sm-20">
                <button
                  type="button"
                  className="d-block m-auto btn btn-primary btn-sm btn-sm-md px-19 py-sm-4 fs-sm-6"
                  onClick={handleClickNextPage}
                >
                  {intl.formatMessage({ id: "AUTH.I_AM_READY" })}
                </button>
              </div>
            </>
          ) : (
            <SeedMnemonicPages />
          )}
        </div>
      </CSSTransition>

      <div className="mb-15 mw-375px mx-auto text-center">
        {error && ShowError(error)}
      </div>

      {resetPass && (
        <div className="d-flex flex-end flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div
            className="link-primary"
            onClick={() => {
              setTimeout(() => {
                nextPage(1);
              }, 500);
            }}
          >
            {intl.formatMessage({ id: "AUTH.RETRY" })}
          </div>
        </div>
      )}

      {/* <div className="d-flex flex-center mb-10">
      <TelegramLoginButton
      botName="TestGaaaarantBot"
      dataOnauth={(user: TelegramUser) => console.log(user)}
      />
    </div> */}
    </div>
  );
}
