import { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { MenuProvider } from "../_metronic/layout/components/menu/_MenuProvider";
import { AuthInit, useAuth } from "./modules/auth";
import { UserProvider } from "./modules/accounts/core/UserContextProvider";

const App = () => {
  // const [devToolsOpen, setDevToolsOpen] = useState(false);
  // const { logout } = useAuth();
  // const { currentUser } = useAuth();

  // const threshold = 160;
  // const isLocalhost = window.location.hostname === "localhost";
  // const isAdmin = currentUser?.roles?.includes("admin") || false;

  // const detectDevTools = () => {
  //   if (
  //     window.outerWidth - window.innerWidth > threshold ||
  //     window.outerHeight - window.innerHeight > threshold
  //   ) {
  //     setDevToolsOpen(true);
  //   }
  // };

  // // const checkDebugger = () => {
  // //   const start = performance.now();
  // //   // debugger;
  // //   const end = performance.now();
  // //   if (end - start > 100) {
  // //     setDevToolsOpen(true);
  // //   }
  // // };

  // // const handleKeyDown = (e: any) => {
  // //   if (
  // //     e.key === "F12" || // F12
  // //     (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "J")) || // Ctrl+Shift+I или Ctrl+Shift+J
  // //     (e.metaKey && e.altKey && e.key === "I") // Cmd+Option+I на Mac
  // //   ) {
  // //     setDevToolsOpen(true);
  // //   }
  // // };

  // useEffect(() => {
  //   if (isLocalhost && isAdmin && currentUser) {
  //     detectDevTools();
  //     // checkDebugger();

  //     setTimeout(() => {
  //       // checkDebugger();
  //       detectDevTools();
  //     }, 2000);

  //     const interval = setInterval(() => {
  //       detectDevTools();
  //       // checkDebugger();
  //       console.log("check");
  //     }, 15000);

  //     window.addEventListener("resize", detectDevTools);
  //     // window.addEventListener("keydown", handleKeyDown);

  //     return () => {
  //       clearInterval(interval);
  //       window.removeEventListener("resize", detectDevTools);
  //       // window.removeEventListener("keydown", handleKeyDown);
  //     };
  //   }
  // }, [currentUser, isAdmin]);

  // useEffect(() => {
  //   if (devToolsOpen) {
  //     setDevToolsOpen(false);
  //     logout();
  //     window.open("/stop-please", "_blank");

  //     window.location.href = "/stop-please";
  //   }
  // }, [devToolsOpen]);

  useEffect(() => {
    const handleTelegramLink = (event: any) => {
      const isPWA = window.matchMedia("(display-mode: standalone)").matches;
      if (isPWA) {
        event.preventDefault();
      }
    };

    window.addEventListener("load", handleTelegramLink);

    return () => {
      window.removeEventListener("load", handleTelegramLink);
    };
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <MenuProvider>
              <UserProvider>
                <Outlet />
                <MasterInit />
              </UserProvider>
            </MenuProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
