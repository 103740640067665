import React, { FC, createContext, useContext } from "react";
import { WithChildren } from "../helpers";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

type Props = {
  selectedLang: "ru" | "en" | "id" | "hi";
};
const initialState: Props = {
  selectedLang: "ru",
};

function getConfig(): Props {
  const userLanguage = navigator.language;
  const lang = userLanguage.split("-")[0].toLowerCase();

  const selectedLang: "ru" | "en" | "id" | "hi" =
    lang === "ru" || lang === "en" || lang === "id" || lang === "hi"
      ? lang
      : "ru";

  const ls = localStorage.getItem(I18N_CONFIG_KEY);

  if (ls) {
    try {
      const config = JSON.parse(ls) as Props;
      if (
        config.selectedLang === "ru" ||
        config.selectedLang === "en" ||
        config.selectedLang === "id" ||
        config.selectedLang === "hi"
      ) {
        return config;
      }
    } catch (er) {
      console.error(er);
    }
  }

  return { ...initialState, selectedLang };
}
// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nContext = createContext<Props>(initialState);

const useLang = () => {
  return useContext(I18nContext).selectedLang;
};

const MetronicI18nProvider: FC<WithChildren> = ({ children }) => {
  const lang = getConfig();

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export { MetronicI18nProvider, useLang };
