import { FC, useEffect, useState } from "react";
import { Balance } from "./Balance";
import { Balance2 } from "./Balance2";
import { useGetBalance } from "../../core/_requests";

interface Props {}

export const BalanceWrapper: FC<Props> = () => {
  const [active, setActive] = useState(true);

  const { data: balance } = useGetBalance({
    refetchInterval: 15000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevToggle) => !prevToggle);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="me-4 ">
      {active ? <Balance balance={balance} /> : <Balance2 balance={balance} />}
    </div>
  );
};
