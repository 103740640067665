import { useAuth } from "../../../../app/modules/auth";
import { Avatar } from "../../../../app/modules/accounts/components/Avatar";
import { Menu } from "../menu/Menu";
import { BlockBtn } from "../../../../app/modules/accounts/components/logout-btns/BlockBtn";
import { useNavigate } from "react-router-dom";
import { BalanceWrapper } from "../../../../app/modules/accounts/components/balance/BalanceWrapper";

const Navbar = () => {
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  return (
    <div className="app-navbar  d-flex gap-2 align-items-center justify-content-between fs-4 fs-lg-6 w-100">
      <Menu />
      {currentUser && (
        <div className="d-flex align-items-center justify-content-end w-100">
          <BalanceWrapper />
          <div
            onClick={() => {
              navigate("/account/settings");
            }}
          >
            <Avatar styles="w-45px h-45px me-4" />
          </div>
          <BlockBtn styles="d-none" />
        </div>
      )}
    </div>
  );
};

export { Navbar };
