import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TelegramLoginButton } from "../../modules/auth/components/TelegramLoginButton";
import { useAuth, UserTelegramWidgetData } from "../../modules/auth";
import { useIntl } from "react-intl";
import { TelegramButton } from "./TelegramButton";
import { TelegramForgotBtn } from "../../modules/auth/components/TelegramForgotBtn";

interface Props {}

export const MainPage: FC<Props> = React.memo(() => {
  const intl = useIntl();

  const { isAuth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [isSucessRegistrationModal, showSucessRegistrationModal] =
    useState(false);

  useEffect(() => {
    if ((location.state as any)?.action?.type === "SUCCESS_REGISTERED") {
      showSucessRegistrationModal(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (isAuth) navigate("/auth");
  }, [isAuth]);

  return (
    <div className="d-flex justify-content-center flex-column flex-column-fluid p-lg-10 py-20">
      <div className="bg-body p-8 p-lg-20 mx-lg-20">
        {isAuth ? (
          <>
            <h1 className="text-dark fw-bold w-lg-400px display-6 mb-7 ms-1">
              {intl.formatMessage({ id: "WELCOME.SAFE_DEALS" })}
            </h1>
          </>
        ) : (
          <>
            <div className="mb-7 ms-1">
              <h1 className="text-dark fw-bold mb-3 w-200px w-lg-400px display-6 mb-3">
                {intl.formatMessage({ id: "AUTH.GENERAL.SIGNUP_TITLE" })}
              </h1>
              <p className="text-gray-500 pt-1 fw-semibold fs-6">
                {intl.formatMessage({ id: "AUTH.SAFE_DEALS.DESCRIPTION" })}
              </p>
            </div>

            <div>
              <div className={`d-block mw-250px`}>
                {/* <button
                  className="btn btn-lg btn-primary text-white mb-2"
                  style={{ borderRadius: "50px" }}
                  onClick={() => {
                    navigate("/auth/login-pass");
                  }}
                >
                  Войти <i className="ki-duotone ki-right text-white "></i>
                </button>
                <button
                  className="btn btn-lg btn-primary text-white mb-12"
                  style={{ borderRadius: "50px" }}
                  onClick={() => {
                    navigate("/auth/registration-pass");
                  }}
                >
                  <i className="ki-duotone ki-left text-white "></i>{" "}
                  Зарегистрироваться
                </button> */}
                <div className="mb-3">
                  <TelegramLoginButton />
                </div>
                <div className="ms-2">
                  <TelegramForgotBtn />
                </div>
              </div>

              <div className={`d-flex mb-2 `}></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
